import { CursorArrowRaysIcon } from "@heroicons/vue/20/solid";

export const propertyDiagramInteractions = {
  order: 4,
  name: "Property diagram interactions",
  subcategory: "Properties",
  icon: CursorArrowRaysIcon,
  iconColor: "text-rose-400",
  markdown: `# Property diagram interactions

  The property diagram helps you discover and create data about properties. It has a number of core interactions, supported by dozens of situational commands. The tables below outline everything that you can do with the diagram, linking to more detailed instruction articles in many cases.

  {% callout type="tip" %}
  **Tip:** New to Tower Hunt? Learn about {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}the role of the property diagram{% /inlineRouterLink %} and {% inlineRouterLink articleId="what-is-a-property?" %}how properties are used in Tower Hunt{% /inlineRouterLink %}.
  {% /callout %}

  ## Core commands

  These commands help you manipulate the diagram to find what you need. Each interaction ties to a {% inlineRouterLink articleId="visual-features-of-the-property-diagram" %}visual section of the diagram{% /inlineRouterLink %}.

  | **Command** | **Interaction(s)** | **Diagram section** |
  |---|---|---|
  | {% inlineRouterLink articleId="timeline" %}Timeline{% /inlineRouterLink %} commands | See {% inlineRouterLink articleId="timeline" %}timeline{% /inlineRouterLink %} | Timeline |
  | {% inlineRouterLink articleId="selecting-and-removing-diagram-properties" %}Select property{% /inlineRouterLink %} | Click property name | Property selector |
  | {% inlineRouterLink articleId="selecting-and-removing-diagram-properties" %}Remove property{% /inlineRouterLink %} | Click {% inlineAppIcon iconName="xMark" /%}**close property button** | Property selector (multiple properties) |
  | {% inlineRouterLink articleId="select-a-property-diagram-part" %}Select diagram part{% /inlineRouterLink %} | Click diagram block | Air rights, floor areas, building levels, ownership units, leaseholds, land coverings, fee parcels, reciprocal easements, land covering groups, same-width building level groups, {% inlineAppIcon iconName="sparkles" /%} enhancements, {% inlineAppIcon iconName="cube" /%} ownership units, {% inlineAppIcon iconName="layout" /%} floor area layouts |
  | {% inlineRouterLink articleId="the-role-of-the-map" sectionName="Mini Map" %}Mini Map{% /inlineRouterLink %} commands | See {% inlineRouterLink articleId="the-role-of-the-map" sectionName="Mini Map" %}Mini Map{% /inlineRouterLink %} | {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}Control panel{% /inlineRouterLink %} |

  ## Primary selection commands

  These commands appear in the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %} when you select specific parts of a property. They often navigate you to important data. When you are editing the diagram, they ask for confirmation.

  {% callout type="tip" %}
  **Tip:** Each command has a keyboard shortcut that is displayed in the upper-left of the button. These can really speed up your contributions once you learn them.
  {% /callout %}

  | **Selection** | **Command** | **Button** | **What happens** |
  |---|---|---|---|
  | Any diagram part **being edited** | Cancel editing | {% inlinePropertyDiagramIcon name="cancelDiagramAdding" /%} | Cancels the current command |
  | Any diagram part **being edited** | {% inlineRouterLink articleId="saving-public-datapoints" %}Save proposed changes{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="saveEditAction" /%} | Saves the proposed changes |
  | All diagram parts **except** fee parcels, leaseholds, ownership units | {% inlineRouterLink articleId="details-tab" %}View details{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="viewDetails" /%} | Navigates to the {% inlineRouterLink articleId="details-tab" %}Details tab{% /inlineRouterLink %} |
  | Air rights, fee parcels, leaseholds, ownership units | {% inlineRouterLink articleId="understand-the-capital-stack" %}View current capital stack{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="singleCapitalStack" /%} | Navigates to the {% inlineAppIcon iconName="capitalStack" /%}{% inlineRouterLink articleId="understand-the-capital-stack" %}**Capital stack**{% /inlineRouterLink %} section of the {% inlineRouterLink articleId="details-tab" %}Details tab{% /inlineRouterLink %} |
  | Air rights, fee parcels, leaseholds, ownership units | {% inlineRouterLink articleId="deal-hub" %}View investments{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="upsertDealBuilder" /%} | Displays the selected asset in the {% inlineRouterLink articleId="deal-hub" %}Deal Hub tab{% /inlineRouterLink %} |
  | Air rights | View air rights diagram | {% inlinePropertyDiagramIcon name="viewAirLayer" /%} | Opens the air rights layer of the diagram |
  | Air rights diagram | View ground-level diagram | {% inlinePropertyDiagramIcon name="viewGroundLayer" /%} | Opens the ground-level layer of the diagram |
  | Fee parcels, leaseholds, ownership units, land coverings, floor areas | {% inlineRouterLink articleId="contacts-tab" %}View contacts{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="contacts" /%} | Navigates to the {% inlineAppIcon iconName="rectangleStack" %}**Property Diagram Contacts**{% /inlineAppIcon %} section of the {% inlineRouterLink articleId="contacts-tab" %}Contacts tab{% /inlineRouterLink %} |
  | Any diagram part | {% inlineRouterLink articleId="add-notes-to-datapoints" %}View notes{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="viewNotes" /%} | Navigates to the {% inlineAppIcon iconName="documentText" %}**Notes**{% /inlineAppIcon %} section of the {% inlineRouterLink articleId="details-tab" %}Details tab{% /inlineRouterLink %} |

  ## Secondary selection commands

  All of these commands help you make {% inlineAppIcon iconName="public" /%}**public** changes to the diagram. They appear in the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %} when you select specific parts of a property.

  {% callout type="tip" marginStyles="mb-4" %}
  **Tip:** Each command has a keyboard shortcut that is displayed in the upper-left of the button. These can really speed up your contributions once you learn them.
  {% /callout %}

  {% callout type="note" %}
  **Note:** While all of these commands make {% inlineAppIcon iconName="public" /%}**public** changes, each part of the diagram allows you to add numerous {% inlineAppIcon iconName="private" /%}{% inlineRouterLink %}**Safezone**{% /inlineRouterLink %} {% inlineRouterLink %}datapoints{% /inlineRouterLink %}.
  {% /callout %}

  | **Selection** | **Command** | **Button** |
  |---|---|---|
  | Property, reciprocal easement | {% inlineRouterLink articleId="add-a-fee-parcel" %}Add fee parcel{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="addFeeSimple" /%} or {% inlinePropertyDiagramIcon name="insertFeeSimple" /%} |
  | Fee parcel, leasehold, land covering ownership unit | {% inlineRouterLink articleId="add-a-leasehold" %}Add leasehold{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="insertGroundLease" /%} |
  | Fee parcel | {% inlineRouterLink articleId="add-a-reciprocal-easement" %}Add reciprocal easement{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="insertReciprocalEasement" /%} |
  | Property, fee parcel | {% inlineRouterLink articleId="add-a-land-covering" %}Add building{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="addBuilding" /%} |
  | Property, fee parcel | {% inlineRouterLink articleId="add-a-land-covering" %}Add surface covering{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="addSurfaceStructure" /%} |
  | Property, land covering, building level, air rights | {% inlineRouterLink articleId="add-a-floor-area" %}Add floor area{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="addFloorArea" /%} |
  | Property, land covering | {% inlineRouterLink articleId="group-land-coverings" %}Group land coverings{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="addLandCoveringGrouping" /%} |
  | Property | {% inlineRouterLink articleId="add-property-enhancement" %}Add enhancement{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="addEnhancement" /%} |
  | Fee parcel | {% inlineRouterLink articleId="shift-fee-parcels-left-or-right" %}Shift fee parcel left{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="shiftFeeSimpleLeft" /%} |
  | Fee parcel | {% inlineRouterLink articleId="shift-fee-parcels-left-or-right" %}Shift fee parcel right{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="shiftFeeSimpleRight" /%} |
  | Land covering, floor area | {% inlineRouterLink articleId="add-an-ownership-unit" %}Add condominium{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="addLandCoveringCondo" /%} |
  | Land covering, floor area | {% inlineRouterLink articleId="add-an-ownership-unit" %}Add co-op{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="addLandCoveringCoop" /%} |
  | Land covering | {% inlineRouterLink articleId="shift-land-coverings-left-or-right" %}Shift land covering left{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="shiftLandCoveringLeft" /%} |
  | Land covering | {% inlineRouterLink articleId="shift-land-coverings-left-or-right" %}Shift land covering right{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="shiftLandCoveringRight" /%} |
  | Land covering | {% inlineRouterLink articleId="add-building-levels" %}Add building levels{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="addLandCoveringLevels" /%} |
  | Land covering | {% inlineRouterLink articleId="relocate-land-coverings" %}Relocate land covering{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="relocateLandCovering" /%} |
  | Land covering | {% inlineRouterLink articleId="set-same-width-building-levels" %}Set same-width building levels{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="addVerticalGrouping" /%} |
  | Land covering level | {% inlineRouterLink articleId="insert-building-levels" %}Insert building levels{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="insertLandCoveringLevels" /%} |
  | Land covering level | {% inlineRouterLink articleId="horizontally-align-building-levels" %}Horizontally align building levels{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="horizontalAlign" /%} |
  | Land covering level | {% inlineRouterLink articleId="raise-or-lower-the-ground-level" %}Raise ground level{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="raiseGroundLevel" /%} |
  | Land covering level | {% inlineRouterLink articleId="raise-or-lower-the-ground-level" %}Lower ground level{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="lowerGroundLevel" /%} |
  | Floor area | {% inlineRouterLink articleId="split-floor-areas" %}Split floor area{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="splitFloorArea" /%} |
  | Floor area | {% inlineRouterLink articleId="relocate-floor-areas" %}Relocate floor area{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="relocateFloorArea" /%} |
  | Floor area | {% inlineRouterLink articleId="horizontally-reorder-floor-areas" %}Horizontally reorder floor areas{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="landCoveringLevelHorizontalReorder" /%} |
  | Floor area | {% inlineRouterLink articleId="vertically-reorder-floor-areas" %}Vertically reorder floor areas{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="verticalReorder" /%} |
  | Floor area | {% inlineRouterLink articleId="create-a-vertical-opening-in-a-floor-area" %}Add vertical opening{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="addVerticalOpenings" /%} |
  | Floor area | {% inlineRouterLink articleId="add-a-floor-area-layout" %}Add floor area layout{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="addLayout" /%} |
  | Floor area {% inlineAppIcon iconName="cube" /%}ownership unit | {% inlineRouterLink articleId="relocate-a-floor-area-ownership-unit" %}Relocate condominium/co-op{% /inlineRouterLink %} | {% inlinePropertyDiagramIcon name="relocateCondoCoop" /%} |`,
};
